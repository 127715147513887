



import React, { useState } from 'react';
import { Box, Button, IconButton, CircularProgress } from '@mui/material';
import 'react-phone-input-2/lib/style.css';
import image1 from '../images/login-2.png';
import image2 from '../images/log-1.png';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';

import 'react-phone-input-2/lib/style.css';
import Verification from './Verification';
import Verified from './Verified';
import List from './List'
import { axiosInstance } from "../api/APIs";
import CloseIcon from '@mui/icons-material/Close';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';



const Modal: React.FC = () => {
    const [phone, setPhone] = useState<string>('');
    const [isValid, setIsValid] = useState<boolean>(true);
    const [UserId, setUserId] = useState<any>("")
    const [showVerification, setShowVerification] = useState<string>('Number');
    const [token, setToken] = useState<any>()
    const [encryptedToken, setEncryptedToken] = useState<object | null>(null);
    const [loading, setLoading] = useState<boolean>(false); // Loader state

    
    const navigate = useNavigate();

    console.log(UserId);

    console.log(token)
    console.log(encryptedToken)


    const handleStore = async () => {
        if (phone.length === 13) {
            setIsValid(true);
            setLoading(true); // Start loading

            const body = {
                mobile_no: phone,
                user_language: "en"
            };
            try {
                const response = await axiosInstance.post("/user/user/loginWithMobileno", body);
                console.log('Response:', response.data.message);
                setShowVerification("Otp");
            } catch (error) {
                console.error('Error fetching data:', error);
            }
            finally {
              setLoading(false); // Stop loading
          }
        } else {
            console.log("Phone number must be exactly 10 digits");
            setIsValid(false);
        }
    };
    const handleClose = () => {
        setShowVerification("Number")

    };
    return (
        <div>

<Box
      sx={{
        display: showVerification === "Number" ? 'flex' : 'none',
        // display:"flex",
        alignItems: 'center',
        justifyContent:"center",
        position: 'absolute',
        height:"100%",
        width:"100%",
  
        zIndex: 10,
      }}
    >
      <Box
        sx={{
          bgcolor: 'white',
        //   border: '0.5px solid',
          borderColor: 'gray.300',
          borderRadius: '20px',
          mx: { xs:2, md: 10 }, // Responsive margins
          overflowY: "hidden",
          width: { xs: '90vw', sm: '70vw', md: '100vw', lg: '70vw', xl: '60vw' },
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          position: 'relative',
          boxShadow: 3, // Add box shadow here

        }}
      >
        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row',height:{xs:"300px",sm:"300px",md:"400px",lg:"400px",xl:"500px"}, }}> 
                                 <Box sx={{width:"10%",display:{xs:"none",sm:"none",md:"none",lg:"flex"}}}>
            
                      <div className=" z-10">
                            <div className="absolute md:-top-[10%] md:-left-[10%] lg:-left-[10%]  sm:-top-[12%] sm:-left-[12%] msm:-top-[20%] msm:-left-[20%] md:w-56 md:h-56  rounded-full" style={{ backgroundColor: "#0CB7D1" }}></div>

                        </div>
            </Box>
            <Box sx={{width:{xs:"100%",sm:"100%",md:"100%",lg:"45%"},height:"100%",justifyContent:"center",display:"flex",alignItems:"center",flexDirection:"column"}}>
            <IconButton
    onClick={() => navigate(-1)}
              sx={{
                position: 'absolute',
                top: 8,
                right: 8,
                display:{xs:"flex",sm:"flex",md:"none",lg:"none"},
                color: 'gray.500',
              }}
            >
              <CloseIcon />
            </IconButton>  <h4 className="font-bold relative  " style={{marginTop:"20px"}}>Enter Your Mobile Number</h4>
                            <Box sx={{ '& > :not(style)': { m: 1 } ,width:"100%",display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column"}} className=''>
                                <Box sx={{ justifyContent: "center", alignItems: "center" }}>
                                    <PhoneInput
                                        defaultCountry="in"
                                        value={phone}
                                        placeholder="Phone Number"
                                        onChange={(phone) => setPhone(phone)}
                                        inputStyle={{
                                            border: 'none',
                                            borderBottom: isValid ? '1px solid lightgray' : '1px solid red',
                                            width: '100%', // This will be controlled by Tailwind
                                        }}
                                        countrySelectorStyleProps={{
                                            buttonStyle: {
                                                border: 0,
                                                borderBottom: '1px solid lightgray',
                                            },
                                        }}
                                        // className="w-[30%] md:w-[80%] msm:w-[80%] flex-none"
                                    />
                                </Box>
                                <div  style={{width:"100%",justifyContent:"center",alignContent:"center",display:"flex",flexDirection:"column",alignItems:"center"}}>
                                    <button
                                        className="bg-[#1063b5] hover:bg-[#1063b5]  mt-2 py-2 px-1 w-40 flex justify-center items-center text-[#fff] font-bold capitalize rounded-2xl "
                                        onClick={handleStore}
                                    >
                                        {loading ? <CircularProgress size={24} color="inherit" /> : 'Verify'}
                                        </button>
                                    <div>
                                    {!isValid && <p className="error text-red-600 text-sm mt-2">Phone number must be exactly 10 digits.</p>}

                                    </div>
                                </div>
                                <div className="w-80">
      <div className="mt-16 text-xs text-center rounded ">
        <div>
          By continuing you agree to Vattara Solutions Private Limited
          <br />
          <p  onClick={() => navigate("/terms")} className="font-bold text-xs hover:underline">
            Terms of Use
          </p>
          {' & '}
          <p  onClick={() => navigate("/privacy")} className="font-bold text-xs hover:underline">
            Privacy Policy
          </p>
        </div>
      </div>
    </div>
                            </Box>    </Box>
                <Box sx={{width:"45%",height:"100%",display:{xs:"none",sm:"none",md:"none",lg:"flex"},flexDirection:"column",justifyContent:"end",alignItems:"end"}}>
              
        <Box sx={{flexDirection:"row",display:"flex",justifyContent:"end",alignItems:"end"}}>
        <IconButton
    onClick={() => navigate(-1)}
              sx={{
                position: 'absolute',
                top: 8,
                right: 8,
                color: 'gray.500',
              }}
            >
              <CloseIcon />
            </IconButton>   <Box sx={{marginX:"20px"}} >
                <img src={image1} alt='img' className='w-full h-full' />
                            </Box>
                            <Box sx={{marginX:"20px",marginTop:"10px"}} >
                            <img src={image2} alt='img' className='w-full h-full' />
                            </Box> </Box>
                </Box>
        </Box>
                    {/* <div className="flex flex-row h-full p-0">
                      
                        <div className="flex-1 w-80 flex md:items-start sm:items-center flex-col  justify-center ml-[5%] mt-[11%] gap-2.5">
                            <div className="absolute right-4  top-3 text-gray-500 text-2xl font-bold cursor-pointer z-20 md"     onClick={() => navigate(-1)} // Inline navigation logic
                            >&times;</div>
                          
                        </div>
                      
                    </div> */}
          </Box>
          </Box>



            <div className={`${showVerification === "Otp" ? "flex" : "hidden"}`}>
                <Verification onClose={handleClose} phone={phone} setUserId={setUserId} setToken={setToken} setEncryptedToken={setEncryptedToken} setShowVerification={setShowVerification} />
            </div>


            <div className={`${showVerification === "tick" ? "flex" : "hidden"}`}>
                <Verified onClose={handleClose} showVerification={showVerification} setShowVerification={setShowVerification} />
            </div>
            <div className={`${showVerification === "List" ? "flex" : "hidden"}`}>
                <List onClose={handleClose} phone={phone} UserId={UserId} token={token}  />
            </div>
        </div>
    );
};

export default Modal;