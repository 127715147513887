import React from 'react'
import plan1 from "../images/plan1.png";
import plan2 from "../images/plan2.png";
import sub1 from "../images/sub1.svg";
import sub2 from '../images/sub2.svg'
import phone from "../images/Phonesub.png";
import subbg from "../images/subBg.svg"
import { MdArrowBackIosNew } from "react-icons/md";
import { Link, useNavigate } from 'react-router-dom';
const Subscription = () => {
    
    const navigate = useNavigate()


    return (
        <div className='bg-[#2E368F] min-h-screen  '>

            <div className='bg-[#EBE8F1] min-h-screen lg:mx-[20%]  rounded-xl '>
                <div className=' mx-[5%]'>
                    <div className='relative flex '>
                        <img src={subbg} className='bg-transparent w-full' />
                        <div className=' msm:flex sm:flex justify-start msm:mt-4 sm:mt-14'>
                            <div onClick={() => navigate("/Profile")}  >
                                <MdArrowBackIosNew className=' w-8 h-8  absolute  left-1 z-40 sm:flex msm:flex mt-1  md:flex' />
                            </div>
                            <p className=' text-3xl msm:right-14  sm:right-[60%]  font-semibold absolute  '>Choose a plan</p>
                        </div>
                    </div>
                    <div className='  lg:flex flex-row  md:flex    msm:flex-col sm:flex  justify-evenly items-center'>
                        <div className='pt-10'>
                            <p className='text- pt-4 font-semibold'>Plan for Property Seekers</p>
                            <img className='mt-6' src={sub1} />
                        </div>
                        <div className='border-white border-1 h-[390px]   msm:hidden md:flex mt-16'></div>
                        <div className='pt-10'>
                            <p className='text- pt-4 font-semibold'>Plan for Property Holders</p>
                            <img className='mt-6' src={sub2} />
                        </div>
                    </div>
                </div>
                {/* <div className=' flex justify-center items-center gap-1 mt-10  msm:pb-4'>
                    <p className=' text-sm text- '>Need help? </p>
                    <p className='  text-sm text-[#006CE7]'>Request Callback</p>
                    <img className='' src={phone} />
                </div> */}
            </div>

        </div>
    )
}

export default Subscription